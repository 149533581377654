import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Image,
    PopoverTrigger,
    PopoverContent,
    Popover
} from "@nextui-org/react";

import { useState, useEffect } from "react";
import axios from "axios";

const EditBusiness = ({ businessId, business, isEditBusinessOpen, onEditBusinessOpenChange, onClose }) => {

    const [businessData, setBusinessData] = useState(null);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        // find the write business based on the businessId
        if (business != null && businessId != null) setBusinessData(business.find(b => b.business_id === businessId));

        if (image) {
            const imageUrl = URL.createObjectURL(image);
            setImageUrl(imageUrl);
        }
    }, [image]);

    const handleFileChange = (e) => {
        console.log(e);
        setImage(e.target.files[0]);
    }

    const submitDelete = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/business/${businessId}`, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then((res) => {
            console.log('Success:', res);
            onEditBusinessOpenChange(false);
        }).catch((error) => {
            console.error('Error:', error);
        });
    }


    const submitEdit = () => {
        const formData = new FormData();
        formData.append('bname', businessData.business_name);
        formData.append('bimage', image);

        fetch(`${process.env.REACT_APP_API_URL}/api/business/${businessId}`, {
            method: 'PUT',
            headers: {
                'Authorization': localStorage.getItem('token')
            },
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                onEditBusinessOpenChange(false);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <>
            {businessData && (

                <Modal
                    isOpen={isEditBusinessOpen}
                    onOpenChange={onEditBusinessOpenChange}
                    placement={"center"}
                    motionProps={{
                        variants: {
                            enter: {
                                y: 0,
                                opacity: 1,
                                transition: {
                                    duration: 0.3,
                                    ease: "easeOut",
                                },
                            },
                            exit: {
                                y: 20,
                                opacity: 0,
                                transition: {
                                    duration: 0.2,
                                    ease: "easeIn",
                                },
                            },
                        }
                    }}
                >
                    <ModalContent>
                        {(onClose) => (
                            <>
                                <ModalHeader className="flex justify-center items-center">Edit your business</ModalHeader>
                                <ModalBody className="flex justify-center items-center w-full h-full pb-4">
                                    <p>{businessData.business_name}</p>
                                    <Input
                                        label="Business name"
                                        isClearable
                                        value={businessData.business_name}
                                        onChange={(e) => setBusinessData({ ...businessData, business_name: e.target.value })}
                                        onClear={() => setBusinessData({ ...businessData, business_name: null })}
                                    />
                                    <Input type="file" accept="image/*" onChange={(e) => handleFileChange(e)} />

                                    <Image
                                        src={imageUrl ? imageUrl : `${process.env.REACT_APP_API_URL}/${businessData.business_image}`}
                                        alt="business image"
                                        width={"100"}
                                        shadow="sm"
                                    />
                                </ModalBody>

                                <ModalFooter>
                                    <Button color="primary" onPress={submitEdit}>
                                        Edit the business
                                    </Button>
                                    <Popover backdrop='opaque'>
                                        <PopoverTrigger>
                                            <Button color="danger" variant='bordered'>Delete</Button>
                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <div className="px-2 py-4">
                                                <div className="text-small font-bold">Are you sure you want to delete this business ?</div>
                                                <div className="text-small">This action is irreversible</div>
                                                <div className="flex pt-4 justify-center gap-2">
                                                    <Button color="danger" onPress={submitDelete}>Confirm</Button>
                                                </div>
                                            </div>
                                        </PopoverContent>
                                    </Popover>
                                </ModalFooter>
                            </>
                        )}
                    </ModalContent>
                </Modal>
            )}
        </>
    );
}

export default EditBusiness;
