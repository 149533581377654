
import QRScanner from "../../components/QRScanner";
import { useEffect } from "react";

import {
    useDisclosure,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Button,
    ModalFooter,
    Select, SelectSection, SelectItem
} from "@nextui-org/react";

import { useState } from "react";
import { CheckIcon } from "../../assets/images/CheckIcon";
import Cross from "../../assets/images/Cross";
import ArrowBackIcon from "../../assets/images/ArrowBackIcon";
import { MenuIcon } from "../../assets/images/MenuIcon";
import WaitingList from "./WaitingList";

import axios from "axios";

const Scan = ({ business, closeScan }) => {
    const { isOpen: isValidateOpen, onOpenChange: onValidateOpenChange } = useDisclosure();
    const { isOpen: isWaitingOpen, onOpenChange: onWaitingOpenChange } = useDisclosure();

    const [queueList, setQueueList] = useState([]);
    const [currentQueue, setCurrentQueue] = useState('');
    const [currentQueueName, setCurrentQueueName] = useState('');

    const [data, setData] = useState({ firstName: null, lastName: null, email: null, position: null });

    /*temp*/
    useEffect(() => {
        console.log(currentQueue);
    }, [currentQueue]);

    // set queueList after fetching from API
    useEffect(() => {

        const tempQueueList = [];
        for (let i = 0; i < business.length; i++) {
            var queues = business[i].queues;
            for (let j = 0; j < queues.length; j++) {
                tempQueueList.push({ business_name: business[i].business_name, queue_id: queues[j].queue_id, queue_name: queues[j].queue_name });
            }
        }
        setQueueList(tempQueueList);
        console.log(tempQueueList);
    }, [business]);

    const computeScannedQR = (data) => {
        if (currentQueue === '' || !currentQueue) { alert("Select a queue to scan QR") }
        else {
            const url = data;
            const urlParts = url.split('/');
            var keyword = urlParts[urlParts.length - 2];
            var userId = urlParts[urlParts.length - 1];
            if (keyword !== 'userQR' || isNaN(userId)) {
                alert("Invalid QR code");
                return;
            }

            axios.get(`${process.env.REACT_APP_API_URL}/api/queue/${currentQueue}/waiter/${userId}`,
                {
                    headers: {
                        'Authorization': localStorage.getItem('token')
                    }
                })

                .then((res) => {
                    if (res.data.length === 0) {
                        alert("User not in the queue")
                        return;
                    }
                    setData(res.data)
                    console.log(res.data)
                    onValidateOpenChange(true);
                })
                .catch(e => {
                    console.log(e);
                }
                )

        }
    }


    const accept = (userId) => {
        console.log(userId)
        axios.post(`${process.env.REACT_APP_API_URL}/api/queue/${currentQueue}/accept`, {
            user_id: userId
        }, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        })
            .then((res) => {
                console.log('accepted')
            })
            .catch(e => {
                console.log(e);
            });
    }

    const reject = (userId) => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/queue/${currentQueue}/reject`, {
            user_id: userId
        }, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        })
            .then((res) => {
                console.log('rejected')
            })
            .catch(e => {
                console.log(e);
            }
            )
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-black bg-opacity-50">
            <div className="fixed top-0 flex justify-between w-full gap-4 items-center p-4">
                <Button onClick={() => { closeScan() }} isIconOnly><ArrowBackIcon size={8} /></Button>
            </div>
            <QRScanner handleScan={(data) => { if (!isValidateOpen) { computeScannedQR(data); } }} />
            <Modal isOpen={isValidateOpen} onOpenChange={onValidateOpenChange} placement={"bottom"} motionProps={{ variants: { enter: { y: 0, opacity: 1, transition: { duration: 0.3, ease: "easeOut", }, }, exit: { y: 20, opacity: 0, transition: { duration: 0.2, ease: "easeIn", }, }, } }} >
                <ModalContent>
                    <ModalHeader className="flex justify-center items-center">Scanned QR - {currentQueueName}</ModalHeader>
                    <ModalBody className="flex justify-center items-center w-full h-full pb-4">
                        {data.userInQueue_rank === 1 ? <CheckIcon size={12} color="green" /> : <Cross size={12} color="red" />
                        }
                        <p className="text-2xl font-bold text-center">Welcome {data.user_firstname} {data.user_lastname}</p>
                        <p className="text-lg text-center">Position {data.userInQueue_rank}</p>
                    </ModalBody>
                    <ModalFooter>

                        <Button color="danger" variant="bordered"
                            onClick={() => { reject(data.user_id); onValidateOpenChange(false); }}>Refuse</Button>
                        <Button color="success"
                            onClick={() => { accept(data.user_id); onValidateOpenChange(false); }}>Accept</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <div className="fixed bottom-0 p-4 w-full flex flex-row justify-between items-center gap-2">
                <Select label="Select a queue" className='w-full' onChange={(e) => { setCurrentQueue(e.target.value); for (let i = 0; i < queueList.length; i++) { if (queueList[i].queue_id == e.target.value) { setCurrentQueueName(queueList[i].queue_name) } } }}>
                    <SelectSection title="Queues">
                        {queueList && queueList.map((queue) => (
                            <SelectItem key={queue.queue_id} value={queue.queue_id} textValue={`${queue.business_name} - ${queue.queue_name}`}
                            ><span className='font-semibold'>{queue.business_name}</span> - {queue.queue_name}</SelectItem>))}
                    </SelectSection>
                </Select>
                <Button isIconOnly aria-label="Waiting List" size="lg" onClick={() => { if (currentQueue !== '') { onWaitingOpenChange() } else { alert("Select a queue to see the list") }; }}
                ><MenuIcon size='6' /></Button>
            </div>
            <WaitingList queueId={currentQueue} isWaitingOpen={isWaitingOpen} onWaitingOpenChange={onWaitingOpenChange} />
        </div>
    );
}

export default Scan;