import React from 'react';
import { useState, useEffect } from 'react';
import QRCode from '../../components/QRCode.jsx';
import QRScanner from '../../components/QRScanner.jsx';
import { redirectIfNotLogged } from '../../utils';


import Search from './Search.jsx';
import QueueRecap from './QueueRecap.jsx';
import JoinQueue from './JoinQueue.jsx';
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem
} from "@nextui-org/react";
import SearchIcon from '../../assets/images/SearchIcon.jsx';
import SettingsIcon from '../../assets/images/SettingsIcon.jsx';
import Account from './Account.jsx';
import axios from 'axios';


const MyQRCode = ({ user_id, isMyQROpen, onMyQROpenChange, onClose }) => {
  return (
    <Modal
      isOpen={isMyQROpen}
      onOpenChange={onMyQROpenChange}
      placement={"bottom"}
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: "easeOut",
            },
          },
          exit: {
            y: 20,
            opacity: 0,
            transition: {
              duration: 0.2,
              ease: "easeIn",
            },
          },
        }
      }}

    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex justify-center items-center">Your QR</ModalHeader>
            <ModalBody className="flex justify-center items-center w-full h-full pb-4">
              <QRCode url={`https://soweskip.maelaubert/userQR/${user_id}`}/>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

const Home = () => {

  const { isOpen: isMyQROpen, onOpen: onMyQROpen, onOpenChange: onMyQROpenChange } = useDisclosure();
  const { isOpen: isJoinOpen, onOpen: onJoinOpen, onOpenChange: onJoinOpenChange } = useDisclosure();
  const { isOpen: isSearchOpen, onOpen: onSearchOpen, onOpenChange: onSearchOpenChange } = useDisclosure();
  const { isOpen: isAccountOpen, onOpen: onAccountOpen, onOpenChange: onAccountOpenChange } = useDisclosure();
  const [queue, setQueue] = useState({
    business_name: "Disneyland", queue_name: "Entrance", userInQueue_rank: 5, time: "5 minutes"
  });

  const [scannedId, setScannedId] = useState(0);
  const [me, setMe] = useState('')

  useEffect(() => {
    redirectIfNotLogged();

    axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      console.log(response.data);
      setMe(response.data.user);
    })
    .catch((error) => {
      console.error(error);
    });

    updateQueue();
    
  }, []);
  

  const updateQueue = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/queue/user/userinqueue`, {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      console.log("queue");
      console.log(response.data);
      setQueue(response.data);  
    })
    .catch((error) => {
      console.error(error);
    });
  }

  
  useEffect(() => {
    const interval = setInterval(() => {
      updateQueue();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  
  

  const Disconnect = () => {
    // remove token from local storage
    localStorage.removeItem('token');
    // redirect to login page
    window.location.href = '/login';
  }


  const computeScannedQR = (data) => {

    const url = data;
    const urlParts = url.split('/');
    var keyword = urlParts[urlParts.length - 2];
    var userId = urlParts[urlParts.length - 1];
    if (keyword !== 'join') {
      alert("Invalid QR code");
      return;
    }
    setScannedId(userId);
    // timeout of 10ms to let the state update
    setTimeout(() => {
      onJoinOpenChange(true);
    }, 10);
  }

  return (
    <div className='flex flex-col items-center justify-between h-screen p-3 z-20'>
      <QRScanner handleScan={(data) => { computeScannedQR(data);}} />

      
      <QueueRecap queue={queue} />
      

      <div className='w-full flex flex-row justify-around items-end'>
        <Button isIconOnly variant="flat" aria-label="Search" size="lg" color="primary" onClick={() => { onSearchOpen(); }}>
          <SearchIcon size={6} color="white" />
          </Button>
        <Button isIconOnly variant="solid" aria-label="QR Code" size="lg" className="w-16 h-16"
        color="primary" onClick={() => { onMyQROpen(); }}>
          <img className='w-full h-full p-3'
            src={`${process.env.PUBLIC_URL}/assets/images/qr-icon_white.svg`} alt="camera" />
        </Button>
        
        <Dropdown>
          <DropdownTrigger>
            <Button isIconOnly variant="flat" aria-label="Profile" size="lg" color="primary">
              <SettingsIcon size={6} color="white" className="p-5" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Static Actions">
            <DropdownItem key="account" onClick={() => { onAccountOpen(); }}>My Account</DropdownItem>
            <DropdownItem key="business" onClick={() => {window.location.href = "/business"}}>Switch to Business Mode</DropdownItem>
            <DropdownItem key="disconnect" className="text-danger" color="danger" onClick={() => {Disconnect()}}>Disconnect</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>



      <MyQRCode user_id={me.user_id} isMyQROpen={isMyQROpen} onMyQROpenChange={onMyQROpenChange} onClose={() => { onMyQROpenChange(false); }}
        className="z-10"
      />

      <JoinQueue queueId={scannedId} me_id={me.user_id}
       isJoinOpen={isJoinOpen} onJoinOpenChange={onJoinOpenChange} onClose={() => { onJoinOpenChange(false); }} />

      <Search isSearchOpen={isSearchOpen} onSearchOpenChange={onSearchOpenChange} onClose={() => { onSearchOpenChange(false); }} />
      
      <Account me={me} isAccountOpen={isAccountOpen} onAccountOpenChange={onAccountOpenChange} onClose={() => { onAccountOpenChange(false); }} />
    </div>
  );
}

export default Home;