import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Image,
    PopoverTrigger,
    PopoverContent,
    Popover
} from "@nextui-org/react";

import { useState, useEffect } from "react";
import axios from "axios";

const EditQueue = ({ queueId, queue, business_name, isEditOpen, onEditOpenChange, onClose }) => {

    const [queueData, setQueueData] = useState(null);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        if(queue != null) setQueueData(queue);
        if (image) {
            const imageUrl = URL.createObjectURL(image);
            setImageUrl(imageUrl);
        }
        console.log(queueData);
    }, [image]);

    const handleFileChange = (e) => {
        console.log(e);
        console.log(e.target.files[0]);
        setImage(e.target.files[0]);
    }

    const submitDelete = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/queue/${queueId}`, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then((res) => {
            console.log('Success:', res);
            onEditOpenChange(false);
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    const submitEdit = () => {
        const formData = new FormData();
        formData.append('qname', queueData.queue_name);
        formData.append('qdescription', queueData.queue_description);
        formData.append('qmax', queueData.queue_max);
        formData.append('qimage', image);

        fetch(`${process.env.REACT_APP_API_URL}/api/queue/${queueId}`, {
            method: 'PUT',
            headers: {
                'Authorization': localStorage.getItem('token')
            },
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                onEditOpenChange(false);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    return (
        <Modal
            isOpen={isEditOpen}
            onOpenChange={onEditOpenChange}
            placement={"center"}
            motionProps={{
                variants: {
                    enter: {
                        y: 0,
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                            ease: "easeOut",
                        },
                    },
                    exit: {
                        y: 20,
                        opacity: 0,
                        transition: {
                            duration: 0.2,
                            ease: "easeIn",
                        },
                    },
                }
            }}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex justify-center items-center">Edit your queue</ModalHeader>
                        <ModalBody className="flex justify-center items-center w-full h-full pb-4">
                            <p>{business_name}</p>
                            <Input
                                label="Queue name"
                                isClearable
                                value={queueData.queue_name}
                                onChange={(e) => setQueueData({ ...queueData, queue_name: e.target.value })}
                                onClear={() => setQueueData({ ...queueData, queue_name: "" })}
                            />
                            <Input
                                isClearable
                                label="Queue description"
                                value={queueData.queue_description}
                                onChange={(e) => setQueueData({ ...queueData, queue_description: e.target.value })}
                                onClear={() => setQueueData({ ...queueData, queue_description: "" })}
                            />
                            <Input
                                isClearable
                                type="number"
                                label="Queue max people"
                                value={queueData.queue_max}
                                onChange={(e) => setQueueData({ ...queueData, queue_max: e.target.value })}
                                onClear={() => setQueueData({ ...queueData, queue_max: "" })}
                            />
                            <Input type="file" accept="image/*" onChange={(e) => handleFileChange(e)} />

                            <Image
                                src={imageUrl ? imageUrl : `${process.env.REACT_APP_API_URL}/${queueData.queue_image}`}
                                alt="queue image"
                                width={"100"}
                                shadow="sm"
                            />
                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" onPress={submitEdit}>
                                Edit the queue
                            </Button>
                            <Popover backdrop='opaque'>
                                <PopoverTrigger>
                                    <Button color="danger" variant='bordered'>Delete</Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <div className="px-2 py-4">
                                        <div className="text-small font-bold">Are you sure you want to delete this queue ?</div>
                                        <div className="text-small">This action is irreversible</div>
                                        <div className="flex pt-4 justify-center gap-2">
                                            <Button color="danger" onClick={submitDelete}>Delete</Button>
                                        </div>
                                    </div>
                                </PopoverContent>
                            </Popover>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}

export default EditQueue;
