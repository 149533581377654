import React from "react";
import { useState, useEffect} from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button} from "@nextui-org/react";
import axios from "axios";

const JoinQueue = ({queueId, me_id, isJoinOpen, onJoinOpenChange, onClose}) => {
    
    const [queueData, setQueueData] = useState(null);

    // request the data of the queueID
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/queue/${queueId}`,
        {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        })
        .then((res) => {
            setQueueData(res.data);
        })
        .catch(e => {
            console.log(e);
        });
    }
    , [queueId]);
    
    const joinQueue = () => {
      console.log('joining');
      console.log(me_id);
        axios.post(`${process.env.REACT_APP_API_URL}/api/queue/${queueId}/join`, {
          userid: me_id
        }, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        })
        .then((res) => {
            console.log('joined');
            onJoinOpenChange(false);
        })
        .catch(e => {
            console.log(e);
        });
    }

    return (
        <Modal
      isOpen={isJoinOpen}
      onOpenChange={onJoinOpenChange}
      placement={"center"}
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: "easeOut",
            },
          },
          exit: {
            y: 20,
            opacity: 0,
            transition: {
              duration: 0.2,
              ease: "easeIn",
            },
          },
        }
      }}

    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex justify-center items-center">{queueData.business_name} - {queueData.queue_name}</ModalHeader>
            <ModalBody className="flex justify-center items-center w-full h-full pb-4">
                <p>{queueData.nbInQueue} people.</p>
                <p>Estimated time : {queueData.nbInQueue*5}</p>
                <p>ID: {queueId}</p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onPress={() => {joinQueue()}}>
                    Join the queue
                </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
      
    </Modal>
    );
    }

export default JoinQueue;