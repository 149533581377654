import React from "react";
export const MenuIcon = ({
  size,
  color
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke={`${color || "currentColor"}`} className={`w-${size} h-${size}`}>
      <script xmlns=""/>
<path d="M4 6H20M7 12H17M9 18H15"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<script xmlns="" id="bw-fido2-page-script"/><script xmlns=""/></svg>
  );
};
