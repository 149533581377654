import { useEffect } from 'react';
import { QRCode as QR } from 'react-qrcode-logo';

const QRCode = ({ url, image = `${process.env.REACT_APP_PUBLIC_URL}/assets/images/LogoSoWeSkip.svg`, className }) => {

    
    return (
        <div className={className}>
        <QR
            value={url}
            size={256}
            logoImage={image}
            logoWidth={64} logoHeight={64} 
            removeQrCodeBehindLogo={true} logoPadding={4}
            eyeRadius={8} qrStyle={"dots"}
            ecLevel='H'
        />
        </div>

    )
}

export default QRCode;