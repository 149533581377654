import React from "react";
import { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  Accordion,
  AccordionItem,
  Avatar,
  Chip,
  Select,
  SelectItem,
} from "@nextui-org/react";
import axios from "axios";

const Search = ({ isSearchOpen, onSearchOpenChange, onClose }) => {
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/queue/search`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          search: search,
          filters: filters,
          sort: sort,
        },
      })
      .then((response) => {
        console.log(response.data);
        setResults(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [search, filters, sort]);

  return (
    <Modal
      isOpen={isSearchOpen}
      onOpenChange={onSearchOpenChange}
      placement={"center"}
      className="h-4/6"
      motionProps={{
        variants: {
          enter: {
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: "easeOut",
            },
          },
          exit: {
            x: -20,
            opacity: 0,
            transition: {
              duration: 0.2,
              ease: "easeIn",
            },
          },
        },
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex justify-center items-center"></ModalHeader>
            <ModalBody className="flex justify-center items-center w-full h-full">
              <Input
                isClearable
                onClear={() => setSearch("")}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search for a queue"
              />
              <div className="flex flex-row w-full gap-2">
                <Select
                  label="Filters"
                  size="sm"
                  placeholder="Filters"
                  selectionMode="multiple"
                  className="w-1/2"
                  onChange={(e) => setFilters(e.target.value)}
                >
                  <SelectItem key={"showclosed"} value={"showclosed"}>
                    Show Closed
                  </SelectItem>
                </Select>
                <Select
                  size="sm"
                  label="Sort by"
                  placeholder="Sort by"
                  className="w-1/2"
                  onChange={(e) => setSort(e.target.value)}
                >
                  <SelectItem key={"timeleft"} value={"timeleft"}>
                    Time Left
                  </SelectItem>
                  <SelectItem key={"distance"} value={"distance"}>
                    Distance
                  </SelectItem>
                </Select>
              </div>

              <Accordion
                isCompact
                /* make the accordion scrollable */
                className="w-full h-auto overflow-y-auto"
                //hide the scrollbar
                style={{ scrollbarWidth: "none", paddingBottom: "128px" }}
              >
                {results.length > 0 &&
                  console.log(results) &&
                  results?.map((result) => (
                    <AccordionItem
                      key={result.queue_id}
                      aria-label={result.business_name + result.queue_name}
                      startContent={
                        <Avatar
                          isBordered
                          radius="lg"
                          src={result.queue_image}
                        />
                      }
                      subtitle={
                        result.queue_open ? (
                          `${result.nbInQueue} people - ${
                            result.nbInQueue * 2
                          } min`
                        ) : (
                          <Chip color="danger" size="sm" variant="faded">
                            Closed
                          </Chip>
                        )
                      }
                      title={
                        <span>
                          <span className="font-semibold">
                            {result.business_name}
                          </span>{" "}
                          - {result.queue_name}
                        </span>
                      }
                    >
                      {result.queue_description}
                    </AccordionItem>
                  ))}
              </Accordion>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default Search;
