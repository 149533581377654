import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Image,
    PopoverTrigger,
    PopoverContent,
    Popover
} from "@nextui-org/react";

import { useState, useEffect } from "react";
import axios from "axios";
import { redirectIfNotLogged } from "../../utils";

const AddBusiness = ({ isAddBusinessOpen, onAddBusinessOpenChange, onClose }) => {

    const [businessData, setBusinessData] = useState({ bname: null, bimage: null});
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        redirectIfNotLogged();
    }, []);

    useEffect(() => {
        if (image) {
            const imageUrl = URL.createObjectURL(image);
            setImageUrl(imageUrl);
        }
    }, [image]);

    const handleFileChange = (e) => {
        console.log(e);
        console.log(e.target.files[0]);
        setImage(e.target.files[0]);
    }

    const submitAdd = () => {
        const formData = new FormData();
        formData.append('bimage', image);
        formData.append('bname', businessData.bname);
        

        fetch(`${process.env.REACT_APP_API_URL}/api/business/create`, {
            method: 'POST',
            headers: {
                'Authorization': localStorage.getItem('token')
            },
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                onAddBusinessOpenChange(false);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <Modal
            isOpen={isAddBusinessOpen}
            onOpenChange={onAddBusinessOpenChange}
            placement={"center"}
            motionProps={{
                variants: {
                    enter: {
                        y: 0,
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                            ease: "easeOut",
                        },
                    },
                    exit: {
                        y: 20,
                        opacity: 0,
                        transition: {
                            duration: 0.2,
                            ease: "easeIn",
                        },
                    },
                }
            }}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex justify-center items-center">Create your business</ModalHeader>
                        <ModalBody className="flex justify-center items-center w-full h-full pb-4">
                            <Input
                                label="Business name"
                                isClearable
                                value={businessData.bname}
                                onChange={(e) => setBusinessData({ ...businessData, bname: e.target.value })}
                                onClear={() => setBusinessData({ ...businessData, bname: null })}
                            />
                            <Input type="file" accept="image/*" onChange={(e) => handleFileChange(e)} />

                            <Image
                                src={imageUrl ? imageUrl : businessData.bimage}
                                alt="business image"
                                width={"100"}
                                shadow="sm"
                            />
                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" onPress={submitAdd}>
                                Create the business
                            </Button>
                            
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}

export default AddBusiness;
