import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {CheckIcon} from "../../assets/images/CheckIcon";
import Dots from "../../assets/images/Dots";
import Cross from "../../assets/images/Cross";

import {
    Card, CardHeader, CardBody, CardFooter,
    Divider,
    Progress,
    Spinner ,
    Chip,
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
    Button
} from "@nextui-org/react";
import axios from 'axios';

const QueueRecap = ({ queue }) => {

    const [detailOpen, setDetailOpen] = useState(false);

    const quitQueue = (queue_id) => {
        axios.post('/api/queue/quit/' + queue_id)
        .then(res => {
            console.log(res);
        })
        .catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            { queue ? (
            <motion.div
                layout
                onClick={() => { setDetailOpen(!detailOpen) }}
                className={`${detailOpen || queue===undefined || queue===null? 'w-full' : 'w-1/4'} flex flex-row justify-center items-center`}
            >
                <Card
                    className={`w-full p-2 flex flex-col justify-center items-center z-50`}
                >
                    {queue===undefined || queue===null ? (
                        <p>Scan a QR code to join a queue</p>
                    ) : (
                    detailOpen ? (
                        <CardBody
                            className="flex flex-row justify-around items-center text-sm"
                        >
                            <span className='flex flex-col justify-center items-center w-1/3 text-center'>
                                <h2 className={`text-center font-semibold`}>{queue.business_name}</h2>
                                <span className={`text-center`}>{queue.queue_name}</span>
                            </span>
                                <Chip color={`${queue.userInQueue_rank === 1 ? 'success' : 'primary'}`} size="lg" className={`text-center w-1/3`} variant="faded"
                                startContent={
                                    queue.userInQueue_rank == 0 ?
                                    <CheckIcon size="6" color="#10B981"/> :
                                    <Spinner 
                                        size="sm"
                                        color="primary"
                                    />
                                }
                                >
                                    <p className='text-lg ml-2'>
                                        {queue.userInQueue_rank}</p></Chip>
                                <p className={`text-center w-1/3`}>{queue.userInQueue_rank*2} min left</p>
                            <Dropdown>
                                <DropdownTrigger>
                                    <Button
                                        isIconOnly 
                                        variant="light"
                                    >
                                        <Dots size={8}/>
                                    </Button>
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Static Actions">
                                    <DropdownItem key="delete" className="text-danger" color="danger" startContent={<Cross size={8} color="#EF4444"/>} description="You will lost your place in queue" onClick={() => {quitQueue(queue.queue_id)}}>
                                        Quit the queue
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>

                        </CardBody>
                    ) : (
                        queue.place == 1 ?(
                            <CheckIcon size="6" color="#10B981"/>
                        ) : (
                            <Progress
                            isIndeterminate
                            size="sm"
                            color="primary"
                            className="w-full"
                            onClick={() => { setDetailOpen(!detailOpen) }}
                        />
                        )
                    )
                    )}
                </Card>
            </motion.div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default QueueRecap;
