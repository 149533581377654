import { useState } from "react";
import { useZxing } from "react-zxing";
import { Button } from "@nextui-org/react";
import ScanOutline from '../assets/images/ScanOutline.jsx';

const QRScanner = ({ handleScan }) => {
  const [result, setResult] = useState("");
  const { ref, torch: { on, off, isOn, isAvailable }, } = useZxing({
    onDecodeResult(result) {
      setResult(result.getText());
      handleScan(result.getText());
    },
  });

  return (
    <div className='flex flex-col items-center justify-center absolute top-0 left-0 w-full h-full -z-50'>
      {isAvailable &&
        <Button onClick={() => (isOn ? off() : on())} className='absolute top-4 right-4' size="sm">
          <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none"><script xmlns="" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0553 15.7213C7.1797 15.7044 6.48357 14.9809 6.5003 14.1053V10.8733C6.48357 9.99772 7.1797 9.27428 8.0553 9.25732H13.7153C14.074 9.25645 14.4209 9.12855 14.6943 8.89632L16.5253 7.35332C16.9965 6.96545 17.6514 6.89018 18.1983 7.16103C18.7452 7.43189 19.0823 7.99846 19.0593 8.60832V16.3703C19.0823 16.9802 18.7452 17.5467 18.1983 17.8176C17.6514 18.0885 16.9965 18.0132 16.5253 17.6253L14.6943 16.0823C14.4209 15.8501 14.074 15.7222 13.7153 15.7213H8.0553Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.6183 12.4894V15.7214" stroke="#000000" stroke-width="1.5" stroke-linecap="round" />
            <script xmlns="" id="bw-fido2-page-script" /><script xmlns="" /></svg>
        </Button>
      }
      <ScanOutline alt="logo" className='w-80 h-80 object-cover absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 opacity-50' />
      <img src={`${process.env.PUBLIC_URL}/assets/images/LogoSoWeSkip_white.svg`} alt="logo" className='w-16 h-16  absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 opacity-50' />
      <video ref={ref} className={"w-full h-full object-cover"} />
    </div>
  );
};

export default QRScanner;