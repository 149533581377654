import React, { useEffect, useState } from 'react';
import Logo from '../../assets/images/Logo';
import axios from 'axios';


import { Card, CardBody, Input, Button, Link, Tabs, Tab } from '@nextui-org/react';

const Login = () => {
    

const [selected, setSelected] = React.useState("login");

const [firstname, setFirstname] = useState("");
const [lastname, setLastname] = useState("");
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");

const clearFields = () => {
    setFirstname("");
    setLastname("");
    setEmail("");
    setPassword("");
}

const handleLogin = async () => {
    axios.post(`${process.env.REACT_APP_API_URL}/api/users/login`,{
        email,
        password
    }).then((res)=> {
        localStorage.setItem('token', res.data.accessToken)
        window.location.href = '/';
    }).catch(e=>{
        alert(e)
    })
}

const handleRegister = async () => {
    axios.post(`${process.env.REACT_APP_API_URL}/api/users/register`,{
        firstname,
        lastname,
        email,
        password
    }).then((res)=> {
        localStorage.setItem('token', res.data.accessToken)
        window.location.href = '/';
    }).catch(e=>{
        alert(e)
    })
}


return (
    <div className="flex flex-col items-center justify-between h-dvh p-6">
        <div className="flex flex-col items-center justify-start w-full h-1/6">
            <Logo className="w-1/3" />
        </div>
        <div className="flex flex-col w-full h-full justify-center items-center">
            <Card className="max-w-full w-full h-3/4" shadow='none'>
                <CardBody className="overflow-hidden">
                    <Tabs
                        fullWidth
                        size="md"
                        aria-label="Tabs form"
                        selectedKey={selected}
                        onSelectionChange={setSelected}
                        onChange={clearFields}
                    >
                        <Tab key="login" title="Login">
                            <form className="flex flex-col gap-4">
                                <Input isRequired label="Email" placeholder="Enter your email" type="email" onChange={(e) => setEmail(e.target.value)} />
                                <Input
                                    isRequired
                                    label="Password"
                                    placeholder="Enter your password"
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <p className="text-center text-small">
                                    Need to create an account?{" "}
                                    <Link size="sm" onPress={() => { clearFields(); setSelected("sign-up") }}>
                                        Sign up
                                    </Link>
                                </p>
                                <div className="flex gap-2 justify-end">
                                    <Button fullWidth color="primary" onClick={handleLogin}>
                                        Login
                                    </Button>
                                </div>
                            </form>
                        </Tab>
                        <Tab key="sign-up" title="Sign up">
                            <form className="flex flex-col gap-4">
                                <Input isRequired label="Firsname" placeholder="Enter your firstname" onChange={(e) => setFirstname(e.target.value)} />
                                <Input isRequired label="Lastname" placeholder="Enter your lastname" onChange={(e) => setLastname(e.target.value)} />
                                <Input isRequired label="Email" placeholder="Enter your email" type="email" onChange={(e) => setEmail(e.target.value)} />
                                <Input isRequired label="Password" placeholder="Enter your password" type="password" onChange={(e) => setPassword(e.target.value)}/>
                                <p className="text-center text-small">
                                    Already have an account?{" "}
                                    <Link size="sm" onPress={() => { clearFields(); setSelected("login") }}>
                                        Login
                                    </Link>
                                </p>
                                <div className="flex gap-2 justify-end">
                                    <Button fullWidth color="primary" onClick={handleRegister}>
                                        Sign up
                                    </Button>
                                </div>
                            </form>
                        </Tab>
                    </Tabs>
                </CardBody>
            </Card>
        </div>
    </div>
)

}

export default Login;