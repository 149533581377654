import { useEffect } from "react";
import { useState } from "react";

import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    TableHeader,
    TableBody,
    TableColumn,
    TableRow,
    TableCell,
    Button,
} from "@nextui-org/react";
import { CheckIcon } from "../../assets/images/CheckIcon";
import Cross from "../../assets/images/Cross";
import ReloadIcon from '../../assets/images/ReloadIcon'

import axios from "axios";


const WaitingList = ({ queueId, isWaitingOpen, onWaitingOpenChange, onClose }) => {
    const [members, setMembers] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    const loadData = () => {
        if (queueId === '' || !queueId) {
            return;
        }
        console.log(queueId);
        axios.get(`${process.env.REACT_APP_API_URL}/api/queue/${queueId}/waitinglist`, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then((res) => {
            console.log(res.data);
            setMembers(res.data);
            setDataLoaded(true);
        }).catch(e => {
            console.log(e);
        });
    }

    useEffect(() => {
        loadData();
    }, [onWaitingOpenChange]);


    const accept = (userId) => {
        console.log(userId)
        axios.post(`${process.env.REACT_APP_API_URL}/api/queue/${queueId}/accept`, {
            user_id: userId
        }, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        })
            .then((res) => {
                loadData();
            })
            .catch(e => {
                console.log(e);
            });
    }

    const reject = (userId) => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/queue/${queueId}/reject`, {
            user_id: userId
        }, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        })
            .then((res) => {
                loadData();
            })
            .catch(e => {
                console.log(e);
            }
            )
    };

    return (
        <>
            {dataLoaded &&
                <Modal isOpen={isWaitingOpen} onOpenChange={onWaitingOpenChange} placement={"bottom"} motionProps={{ variants: { enter: { y: 0, opacity: 1, transition: { duration: 0.3, ease: "easeOut", }, }, exit: { y: 20, opacity: 0, transition: { duration: 0.2, ease: "easeIn", }, }, } }}>


                    <ModalContent className="h-5/6 flex flex-col">
                        {(onClose) => (
                            <div className="flex flex-col h-full">
                                <ModalHeader className="flex justify-center items-center relative ">
                                    <Button isIconOnly onClick={() => loadData()} color='primary' className="absolute top-2 left-2"><ReloadIcon color='#ffffff' size="8" /></Button>
                                    Waiting List
                                    <div></div>
                                </ModalHeader>
                                <ModalBody className="flex justify-center items-center w-full h-full">
                                    <Table aria-label="Waiting list" className="w-full h-full overflow-auto" isHeaderSticky>
                                        <TableHeader>
                                            <TableColumn>Position</TableColumn>
                                            <TableColumn>Name</TableColumn>
                                            <TableColumn>Actions</TableColumn>
                                        </TableHeader>
                                        <TableBody>
                                            {members?.map((member) => (
                                                <TableRow key={member.user_id}>
                                                    <TableCell>{member.userInQueue_rank}</TableCell>
                                                    <TableCell>{member.user_firstname} {member.user_lastname}</TableCell>
                                                    <TableCell className="flex gap-1">
                                                        <Button
                                                            isIconOnly
                                                            size='sm'
                                                            variant="flat"
                                                            onClick={() => { accept(member.user_id) }}
                                                        >
                                                            <CheckIcon size="4" color="green" />
                                                        </Button>
                                                        <Button
                                                            isIconOnly
                                                            size='sm'
                                                            variant="flat"
                                                            onClick={() => { reject(member.user_id) }}
                                                        >
                                                            <Cross size="4" color="red" />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </ModalBody>
                                <ModalFooter>
                                </ModalFooter>
                            </div>
                        )}
                    </ModalContent>

                </Modal>

            }
        </>
    );
}

export default WaitingList;