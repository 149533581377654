import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Image,
    PopoverTrigger,
    PopoverContent,
    Popover
} from "@nextui-org/react";

import { useState, useEffect } from "react";

const AddQueue = ({ businessId, isAddQueueOpen, onAddQueueOpenChange, onClose }) => {

    const [queueData, setQueueData] = useState({ bname: null, qname: null, closed: null, qmax: null, qimage: null, qdescription: null });
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        console.log(businessId);
        if (image) {
            const imageUrl = URL.createObjectURL(image);
            setImageUrl(imageUrl);
        }
    }, [image]);

    const handleFileChange = (e) => {
        console.log(e);
        console.log(e.target.files[0]);
        setImage(e.target.files[0]);
    }

    const submitAdd = () => {
        const formData = new FormData();
        formData.append('qname', queueData.qname);
        formData.append('qdescription', queueData.qdescription);
        formData.append('qmax', queueData.qmax);
        formData.append('qimage', image);
        formData.append('bid', businessId);
        

        fetch(`${process.env.REACT_APP_API_URL}/api/queue/create`, {
            method: 'POST',
            headers: {
                'Authorization': localStorage.getItem('token')
            },
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                onAddQueueOpenChange(false);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <Modal
            isOpen={isAddQueueOpen}
            onOpenChange={onAddQueueOpenChange}
            placement={"center"}
            motionProps={{
                variants: {
                    enter: {
                        y: 0,
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                            ease: "easeOut",
                        },
                    },
                    exit: {
                        y: 20,
                        opacity: 0,
                        transition: {
                            duration: 0.2,
                            ease: "easeIn",
                        },
                    },
                }
            }}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex justify-center items-center">Create your queue</ModalHeader>
                        <ModalBody className="flex justify-center items-center w-full h-full pb-4">
                            <Input
                                label="Queue name"
                                isClearable
                                value={queueData.qname}
                                onChange={(e) => setQueueData({ ...queueData, qname: e.target.value })}
                                onClear={() => setQueueData({ ...queueData, qname: null })}
                            />
                            <Input
                                isClearable
                                label="Queue description"
                                value={queueData.qdescription}
                                onChange={(e) => setQueueData({ ...queueData, qdescription: e.target.value })}
                                onClear={() => setQueueData({ ...queueData, qdescription: null })}
                            />
                            <Input
                                isClearable
                                type="number"
                                label="Queue max people"
                                value={queueData.qmax}
                                onChange={(e) => setQueueData({ ...queueData, qmax: e.target.value })}
                                onClear={() => setQueueData({ ...queueData, qmax: null })}
                            />
                            <Input type="file" accept="image/*" onChange={(e) => handleFileChange(e)} />

                            <Image
                                src={imageUrl ? imageUrl : queueData.qimage}
                                alt="queue image"
                                width={"100"}
                                shadow="sm"
                            />
                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" onPress={submitAdd}>
                                Create the queue
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}

export default AddQueue;
