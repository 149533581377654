import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Button,
    ModalFooter,
    Input,
    PopoverTrigger,
    PopoverContent,
    Popover
} from "@nextui-org/react"

import Cross from '../../assets/images/Cross'

import { useState, useEffect } from "react";
import Plus from "../../assets/images/Plus";
import axios from "axios";

const ManageQueueStaff = ({ queueId, isManageStaffOpen, onManageStaffOpenChange, onClose }) => {
    const [staffData, setStaffData] = useState(null);
    const [emailEntered, setEmailEntered] = useState(null);
    const [me, setMe] = useState(null);

    const loadData = () => {
        fetch(`${process.env.REACT_APP_API_URL}/api/queue/${queueId}/staff`, {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setStaffData(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then((res) => {
            setMe(res.data.user);
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    //wait for the component to be mounted
    useEffect(() => {
        loadData();
    }, [isManageStaffOpen]);

    const submitAdd = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/queue/${queueId}/staff`, {
            email: emailEntered
        }, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then((res) => {
            console.log('Success:', res);
            loadData();
        }).catch((error) => {
            if (error.response.status === 404) {
                alert("User not found");
            }
            else {
                console.error('Error:', error);
            }
        });
    }

    const sumbitRemove = (userId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/queue/${queueId}/staff/${userId}`, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then((res) => {
            console.log('Success:', res);
            loadData();
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    return (
        <Modal
            isOpen={isManageStaffOpen}
            onOpenChange={onManageStaffOpenChange}
            placement={"center"}
            motionProps={{
                variants: {
                    enter: {
                        y: 0,
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                            ease: "easeOut",
                        },
                    },
                    exit: {
                        y: 20,
                        opacity: 0,
                        transition: {
                            duration: 0.2,
                            ease: "easeIn",
                        },
                    },
                }
            }}
        >
            <ModalContent className="h-3/4 flex flex-col">
                {(onClose) => (
                    <div className="flex flex-col h-full">
                        <ModalHeader className="flex justify-center around items-center">Manage Staff</ModalHeader>
                        <ModalBody className="flex-grow overflow-auto pb-4">
                            {staffData?.map((person, i) => (
                                <div key={i} className="flex flex-row items-center justify-between gap-2 w-full pb-2 border-b-1">
                                    <span className="flex flex-col text-sm">
                                        <span className="font-semibold">{person.user_firstname} {person.user_lastname}</span>
                                        {person.user_email}
                                    </span>
                                    {person?.user_id !== me.user_id ? (
                                        <Popover backdrop='opaque' placement='left' trigger='click'>
                                            <PopoverTrigger>
                                                <Button color="danger" size="sm" isIconOnly>
                                                    <Cross size='6' color='white' />
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <div className="px-2 py-4">
                                                    <div className="text-small font-bold">Are you sure you want to remove this staff member ?</div>
                                                    <div className="flex pt-4 justify-center gap-2">
                                                        <Button color="danger" onClick={() => sumbitRemove(person.user_id)}>Remove</Button>
                                                    </div>
                                                </div>
                                            </PopoverContent>
                                        </Popover>
                                    ) : (
                                        <span className="text-small font-semibold">You</span>
                                    )}
                                </div>
                            ))}
                        </ModalBody>
                        <ModalFooter>
                            <Input type="email" label="Email" onChange={(e) => setEmailEntered(e.target.value)} endContent={<Button isIconOnly onClick={submitAdd}><Plus size="6" color="black" /></Button>} />
                        </ModalFooter>
                    </div>
                )}
            </ModalContent>
        </Modal>

    );
}


export default ManageQueueStaff;