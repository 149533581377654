const getUser = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/users/me`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${localStorage.getItem('token')}`
      }
    }).then((res) => {
      if (res.status === 200) {
        return res.json().then(data => {
          return data.user;
        });
      }
    }).catch((e) => {
      console.log(e);
    });
  }
  
  const redirectIfNotLogged = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/users/me`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${localStorage.getItem('token')}`
      }
    }).then((res) => {
      if (res.status !== 200) {
        window.location.href = '/login';
      }
    }).catch((e) => {
      console.log(e);
      //window.location.href = '/login';
    });
  }

export { getUser, redirectIfNotLogged };