const ScanOutline = ({className}) => {
  return (
    <svg width="800px" height="800px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{stroke: '#FFFFFF', strokeWidth: '8px'}} className={className}>
      <title>ionicons-v5-k</title>
      <path d="M336,448h56a56,56,0,0,0,56-56V336" style={{fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round'}} />
      <path d="M448,176V120a56,56,0,0,0-56-56H336" style={{fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round'}} />
      <path d="M176,448H120a56,56,0,0,1-56-56V336" style={{fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round'}} />
      <path d="M64,176V120a56,56,0,0,1,56-56h56" style={{fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round'}} />
    </svg>
  );
}

export default ScanOutline;
