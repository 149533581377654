import Logo from "../../assets/images/Logo";
import SettingsIcon from "../../assets/images/SettingsIcon";
import SearchIcon from "../../assets/images/SearchIcon";
import Plus from "../../assets/images/Plus";
import Person from "../../assets/images/Person";
import axios from "axios";

import {
    Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, DropdownSection,
    Button,
    Accordion, AccordionItem,
    Avatar,
    Chip,
    Card, CardBody,
    useDisclosure,
    Modal, ModalContent, ModalHeader, ModalBody,
} from "@nextui-org/react";

import React, { useEffect, useState } from "react";
import EditQueue from "./EditQueue";
import ManageQueueStaff from "./ManageQueueStaff";
import EditBusiness from "./EditBusiness";
import ManageBusinessStaff from "./ManageBusinessStaff";
import AddQueue from "./AddQueue";
import AddBusiness from "./AddBusiness";
import ScanIcon from "../../assets/images/ScanIcon";
import Scan from "./Scan";
import QRCode from '../../components/QRCode';



const Business = () => {

    const [business, setBusiness] = useState(null); //tableau de business, avec leurs queues
    const [selectedBusiness, setSelectedBusiness] = useState(null)
    const [selectedQueue, setSelectedQueue] = useState(null)
    const { isOpen: isEditOpen, onOpen: onEditOpen, onOpenChange: onEditOpenChange } = useDisclosure(false);
    const { isOpen: isEditBusinessOpen, onOpen: onEditBusinessOpen, onOpenChange: onEditBusinessOpenChange } = useDisclosure(false);
    const { isOpen: isManageStaffOpen, onOpen: onManageStaffOpen, onOpenChange: onManageStaffOpenChange } = useDisclosure(false);
    const { isOpen: isManageAdminOpen, onOpen: onManageAdminOpen, onOpenChange: onManageAdminOpenChange } = useDisclosure(false);
    const { isOpen: isAddBusinessOpen, onOpen: onAddBusinessOpen, onOpenChange: onAddBusinessOpenChange } = useDisclosure(false);
    const { isOpen: isAddQueueOpen, onOpen: onAddQueueOpen, onOpenChange: onAddQueueOpenChange } = useDisclosure(false);
    const { isOpen: isqueueQR, onOpen: onqueueQR, onOpenChange: onqueueQRChange } = useDisclosure(false);

    const [scan, setScan] = useState(false);

    const loadData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/business`, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then((res) => {
            console.log(res.data);
            setBusiness(res.data);
            setSelectedBusiness(business[0].business_id);
        }).catch(e => {
            console.log(e);
        });  
    }   

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        console.log("selectedBusiness : " + selectedBusiness);
    }, [selectedBusiness]);
    
    useEffect(() => {
        console.log("selectedQueue : " + selectedQueue);
    }, [selectedQueue]);

    



    const Disconnect = () => {
        localStorage.removeItem('token');
        window.location.href = "/login";
    }

    const toggleOpen = (queue_id) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/queue/toggleOpen`, {
            method: "POST",
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                queue_id: queue_id
            })
        }).then((res) => {
            loadData();
        }).catch(e => {
            console.log(e);
        });
    }

    return (
        <div className="flex flex-col items-center justify-between h-dvh p-6">
            <div className="flex flex-col items-center justify-start w-full h-1/6">
                <Logo className="w-1/3" />
                <p className="text-2xl font-semibold">Business</p>
            </div>
            <div className='w-full flex flex-col justify-start items-center h-4/6'>
                <Accordion
                    /* make the accordion scrollable */
                    className='w-full h-full overflow-y-auto'
                    //hide the scrollbar
                    style={{ scrollbarWidth: "none", paddingBottom: "128px" }}
                >
                    {business !== null && business.map((b, i) => (
                        <AccordionItem key={i} aria-label={b.business_name}
                            startContent={
                                <Avatar
                                    isBordered
                                    radius="lg"
                                    src={`${process.env.REACT_APP_API_URL}/${b.business_image}`}
                                />
                            }
                            title={
                                <div className="flex flex-row items-center w-full">
                                    <span className="font-semibold text-sm w-2/3 wrap">{b.business_name}</span>
                                    {b.business_privilege > 0 &&
                                        <Dropdown>
                                            <DropdownTrigger>
                                                <Button color="primary" variant='flat' size="sm" className='w-1/3 ml-2' onClick={() => { setSelectedBusiness(b.business_id);}}>Manage</Button>
                                            </DropdownTrigger>
                                            <DropdownMenu aria-label="Static Actions">
                                                {b.business_privilege >= 1 &&
                                                    //showDivider only if b.business_privilege === 1
                                                    <DropdownSection title="Admin" showDivider>
                                                        <DropdownItem key="editBusiness" onClick={() => {onEditBusinessOpenChange(true); }} startContent={<SettingsIcon size="4" />}>Edit business</DropdownItem>
                                                        <DropdownItem key="addQueue" onClick={() => {onAddQueueOpenChange(true) }}>Add queue</DropdownItem>
                                                    </DropdownSection>
                                                }
                                                {b.business_privilege >= 2 &&
                                                    <DropdownSection title="Owner" >
                                                        <DropdownItem key="manageAdmin" onClick={() => {onManageAdminOpenChange(true); }} startContent={<Person size="4" />}>Manage Admins</DropdownItem>
                                                    </DropdownSection>
                                                }
                                            </DropdownMenu>
                                        </Dropdown>

                                    }
                                </div>
                            }>

                            <div className="flex w-full flex-wrap justify-center gap-2">
                                {b.queues.length === 0 && (
                                    <p>There is no queue</p>
                                )}
                                
                                {b.queues.map((q, j) => (
                                    (b.business_privilege > 0 || q.queue_privilege !== 0) &&
                                    <Card key={j} className="w-[calc(100%/2-0.5rem)] border-2"
                                        shadow="none">
                                        <CardBody className="w-full flex flex-col justify-between items-center">
                                            <div className="w-full flex flex-row justify-between items-center">
                                                <Avatar
                                                    isBordered
                                                    radius="lg"
                                                    src={q.queue_image === null ? `${process.env.REACT_APP_API_URL}/${b.business_image}` :
                                                        `${process.env.REACT_APP_API_URL}/${q.queue_image}`}
                                                />
                                                <Dropdown>
                                                    <DropdownTrigger>
                                                        <Button color="secondary" variant='flat' size="sm" onClick={() => { setSelectedQueue(q.queue_id); setSelectedBusiness(b.business_id);}}>Manage</Button>
                                                    </DropdownTrigger>
                                                    <DropdownMenu aria-label="Static Actions">
                                                        
                                                            <DropdownSection title="Manage" showDivider>
                                                                <DropdownItem key="editQueue" onClick={() => {onEditOpenChange(true);}} startContent={<SettingsIcon size="4" />}>Edit queue</DropdownItem>
                                                                {q.queue_privilege >= 2 && <DropdownItem key="manageStaff" onClick={() => {onManageStaffOpenChange(true); }} startContent={<Person size="4" />}>Manage Staff</DropdownItem>}
                                                            </DropdownSection>
                                                        <DropdownItem key='queueQR' onClick={() => {onqueueQRChange(true); }}>QR Code</DropdownItem>
                                                        <DropdownItem key="toggleOpen" onClick={() => {toggleOpen(q.queue_id)}}>{q.queue_open ? (<span className="text-danger">Close</span>) : (<span className="text-success">Open</span>)}</DropdownItem>

                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>

                                            <p className="font-semibold">{q.queue_name}</p>
                                            {q.queue_open ? (
                                                <p>- {q.people_in_queue } people<br/>- {q.people_in_queue*2} min wait</p>
                                            ) : (
                                                <Chip className="mt-2" color="danger" size='sm' variant="faded">Closed</Chip>
                                            )}
                                        </CardBody>
                                    </Card>
                                ))}
                            </div>
                        </AccordionItem>
                    ))}
                </Accordion>
                {business === null && (
                    <p>There is no business</p>
                )}

            </div>

            <div className='w-full flex flex-row justify-around items-end h-1/6'>
                <Button isIconOnly variant="solid" aria-label="Search" size="lg" color="primary" onClick={() => {setScan(true)}}>
                    <ScanIcon size={6} color="white" />
                </Button>
                <Button isIconOnly variant="solid" aria-label="QR Code" size="lg" className="w-16 h-16"
                    color="primary" onClick={() => { onAddBusinessOpenChange(true); }}>
                    <Plus color="white" />
                </Button>
                <Dropdown>
                    <DropdownTrigger>
                        <Button isIconOnly variant="solid" aria-label="Profile" size="lg" color="primary">
                            <SettingsIcon size={6} color="white" className="p-5" />
                        </Button>

                    </DropdownTrigger>
                    <DropdownMenu aria-label="Static Actions">
                        <DropdownItem key="business" onClick={() => { window.location.href = "/" }}>Switch to Customer Mode</DropdownItem>
                        <DropdownItem key="disconnect" className="text-danger" color="danger" onClick={() => { Disconnect() }}>Disconnect</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>

            {scan && ( <Scan business={business}
             closeScan={() => {setScan(false)}} /> )}

            <ManageBusinessStaff businessId={selectedBusiness} isManageAdminOpen={isManageAdminOpen} onManageAdminOpenChange={onManageAdminOpenChange} onClose={() => { onManageAdminOpenChange(false); }} />
            <ManageQueueStaff queueId={selectedQueue} isManageStaffOpen={isManageStaffOpen} onManageStaffOpenChange={onManageStaffOpenChange} onClose={() => { onManageStaffOpenChange(false); }} />
            
            {selectedBusiness !== null && business !== null && business.find(b => b.business_id === selectedBusiness) !== undefined && (
            <>
            <EditBusiness businessId={selectedBusiness} business={business} isEditBusinessOpen={isEditBusinessOpen} onEditBusinessOpenChange={onEditBusinessOpenChange} onClose={() => { onEditBusinessOpenChange(false); }} />
            </>
            )}
            {selectedQueue !== null && business !== null && business.find(b => b.business_id === selectedBusiness) !== undefined && (
            <>
            <EditQueue
                queueId={selectedQueue}
                queue={business.find(b => b.business_id === selectedBusiness).queues.find(q => q.queue_id === selectedQueue)}
                business_name={business.find(b => b.business_id === selectedBusiness).business_name}
                isEditOpen={isEditOpen} onEditOpenChange={onEditOpenChange} onClose={() => { onEditOpenChange(false); }} />
            </>
            )}
            <AddBusiness isAddBusinessOpen={isAddBusinessOpen} onAddBusinessOpenChange={onAddBusinessOpenChange} onClose={() => { onAddBusinessOpenChange(false); }} />
            <AddQueue businessId={selectedBusiness} isAddQueueOpen={isAddQueueOpen} onAddQueueOpenChange={onAddQueueOpenChange} onClose={() => { onAddQueueOpenChange(false); }} />
            { selectedBusiness !== null && business !== null && business.find(b => b.business_id === selectedBusiness) !== undefined && (
            <Modal isOpen={isqueueQR} onOpenChange={onqueueQRChange} placement={"bottom"} motionProps={{ variants: { enter: { y: 0, opacity: 1, transition: { duration: 0.3, ease: "easeOut", }, }, exit: { y: 20, opacity: 0, transition: { duration: 0.2, ease: "easeIn", }, }, } }}>
                <ModalContent>
                    <ModalHeader className="flex justify-center items-center">{business.find(b => b.business_id === selectedBusiness)?.business_name} - {business.find(b => b.business_id === selectedBusiness)?.queues.find(q => q.queue_id === selectedQueue)?.queue_name}</ModalHeader>
                    <ModalBody className="flex justify-center items-center w-full h-full pb-4">
                        <QRCode url={`${process.env.REACT_APP_PUBLIC_URL}/join/${selectedQueue}`} image={`${process.env.REACT_APP_API_URL}/${business.find(b => b.business_id === selectedBusiness)?.queues.find(q => q.queue_id === selectedQueue)?.queue_image}`}/>
                    </ModalBody>
                </ModalContent>
            </Modal>
            )}
        </div>
                
    );
}

export default Business;