import React from 'react';

import ReactDOM from 'react-dom/client';

import {BrowserRouter, Route, Routes, useNavigate} from 'react-router-dom';
import {NextUIProvider} from '@nextui-org/react';

import './index.css';

import Home from './pages/Home/Home';
import Business from './pages/Business/Business';
import Login from './pages/Login/Login';

function App(){
  const navigate = useNavigate()
  return(
    <NextUIProvider navigate={navigate} className="h-full">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/business' element={<Business />} />
        <Route path='/login' element={<Login />} />
        <Route path='*' element={<h1>404 - Not Found</h1>} />
      </Routes>
    </NextUIProvider>
  );
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <main className='text-foreground' style={{minHeight: '100vh'}}>
        <App />
      </main>
    </BrowserRouter>
  </React.StrictMode>
);