import React, { useState, useEffect } from 'react';

import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Input,
    Button,
    Divider,
    Switch,
    PopoverTrigger,
    PopoverContent,
    Popover
} from "@nextui-org/react";

import axios from 'axios';

const Account = ({ me, isAccountOpen, onAccountOpenChange, onCloseAccount }) => {

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    useEffect(() => {
        setFirstname(me.user_firstname);
        setLastname(me.user_lastname);
        setEmail(me.user_email);
    }, [me]);

    const handleSave = () => {
        console.log(firstname, lastname, email, password, confirmPassword)
        if (password !== '') {
            if (password !== confirmPassword) {
                alert('Passwords do not match');
                return;
            }
            else {
                axios.put(`${process.env.REACT_APP_API_URL}/api/users/me`, {
                    firstname: firstname,
                    lastname: lastname,
                    password: password
                }, {
                    headers: {
                        'Authorization': localStorage.getItem('token')
                    }
                }).then((res) => {
                    alert('Saved');
                }).catch(e => {
                    console.log(e);
                });
            }
        } else {
            axios.put(`${process.env.REACT_APP_API_URL}/api/users/me`, {
                firstname: firstname,
                lastname: lastname
            }, {
                headers: {
                    'Authorization': localStorage.getItem('token')
                }
            }).then((res) => {
                alert('Saved');
            }).catch(e => {
                console.log(e);
            });
        }
    }

    const handleDelete = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/users/me`, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then((res) => {
            alert('Deleted');
        }).catch(e => {
            if (e.response.status === 403) {
                alert('You are still the owner of a business or a queue... You have to give your rights to someone else or delete them first');
            }
            console.log(e);
        });
    }


            


    return (
        <Modal
            isOpen={isAccountOpen}
            onOpenChange={onAccountOpenChange}
            placement={"center"}
            className='h-4/6'
            motionProps={{
                variants: {
                    enter: {
                        x: 0,
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                            ease: "easeOut",
                        },
                    },
                    exit: {
                        x: 20,
                        opacity: 0,
                        transition: {
                            duration: 0.2,
                            ease: "easeIn",
                        },
                    },
                }
            }}

        >
            <ModalContent>
                {(onCloseAccount) => (
                    <>
                        <ModalHeader className="flex justify-center items-center"></ModalHeader>
                        <ModalBody className="flex justify-start items-center w-full h-full">
                            <p>Account</p>
                            <div className="flex flex-row w-full gap-2">
                                <Input label="First Name" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                                <Input label="Last name" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                            </div>
                            <Input type='email' label="Email" value={email} isDisabled endContent={<span className='text-xs'>You can't change your email</span>} />
                            <Input label="Password" type="password" onChange={(e) => setPassword(e.target.value)} />
                            <Input label="Confirm Password" type="password" onChange={(e) => setConfirmPassword(e.target.value)} />
                            <Button color="primary" onPress={handleSave}
                            >Save</Button>
                            <Divider />
                            <Switch size="sm" >Receive notifications</Switch>
                            <Popover backdrop='opaque'>
                                <PopoverTrigger>
                                    <Button color="danger" variant='bordered'>Delete Account</Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <div className="px-2 py-4">
                                        <div className="text-small font-bold">Are you sure you want to delete your account ?</div>
                                        <div className="text-small">This action is irreversible</div>
                                        <div className="flex pt-4 justify-center gap-2">
                                            <Button color="danger" variant='bordered' onPress={handleDelete}>Delete</Button>
                                        </div>
                                    </div>
                                </PopoverContent>
                            </Popover>

                        </ModalBody>
                    </>
                )}
            </ModalContent>

        </Modal>
    );
}

export default Account;