import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Button,
    ModalFooter,
    Input,
    PopoverTrigger,
    PopoverContent,
    Popover
} from "@nextui-org/react"

import Cross from '../../assets/images/Cross'

import { useState, useEffect } from "react";
import Plus from "../../assets/images/Plus";
import Key from "../../assets/images/Key";
import axios from "axios";

const ManageBusinessStaff = ({ businessId, isManageAdminOpen, onManageAdminOpenChange, onClose }) => {
    const [adminData, setAdminData] = useState(null);
    const [emailEntered, setEmailEntered] = useState(null);
    const [me, setMe] = useState(null);

    const loadData = () => {
        fetch(`${process.env.REACT_APP_API_URL}/api/business/${businessId}/staff`, {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setAdminData(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then((res) => {
            setMe(res.data.user);
        }).catch((error) => {
            console.error('Error:', error);
        });
    }
    
    //wait for the component to be mounted
    useEffect(() => {
        if (isManageAdminOpen) {
            loadData();
        }
    }, [isManageAdminOpen]);

    const submitAdd = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/business/${businessId}/staff`, {
            email: emailEntered
        }, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then((res) => {
            console.log('Success:', res);
            loadData();
        }).catch((error) => {
            if (error.response.status === 404) {
                alert("User not found");
            }
            else{
                console.error('Error:', error);
            }
        });
    }

    const sumbitRemove = (userId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/business/${businessId}/staff/${userId}`, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then((res) => {
            console.log('Success:', res);
            loadData();
            onManageAdminOpenChange(false);
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    const sumbitTransfer = () => {
        axios.put(`${process.env.REACT_APP_API_URL}/api/business/${businessId}/staff/transfer`, {
            email: emailEntered
        }, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then((res) => {
            console.log('Success:', res);
            loadData();
            onManageAdminOpenChange(false);
        }).catch((error) => {
            if (error.response.status === 404) {
                alert("User not found");
            }
            else{
                console.error('Error:', error);
            }
        });
    }

    return (
        <>
            {adminData && (

                <Modal
                    isOpen={isManageAdminOpen}
                    onOpenChange={onManageAdminOpenChange}
                    placement={"center"}
                    motionProps={{
                        variants: {
                            enter: {
                                y: 0,
                                opacity: 1,
                                transition: {
                                    duration: 0.3,
                                    ease: "easeOut",
                                },
                            },
                            exit: {
                                y: 20,
                                opacity: 0,
                                transition: {
                                    duration: 0.2,
                                    ease: "easeIn",
                                },
                            },
                        }
                    }}
                >
                    <ModalContent className="h-3/4 flex flex-col">
                        {(onClose) => (
                            <div className="flex flex-col h-full">
                                <ModalHeader className="flex justify-center around items-center">Manage Admins</ModalHeader>
                                <ModalBody className="flex-grow overflow-auto pb-4">

                                    {adminData.map((person, i) => (
                                        <div key={i} className="flex flex-row items-center justify-between gap-2 w-full pb-2 border-b-1">
                                            <span className="flex flex-col text-sm">
                                                <span className="font-semibold">{person.user_firstname} {person.user_lastname}</span>
                                                {person.user_email}
                                            </span>
                                            { person?.user_id !== me.user_id ? (
                                            <Popover backdrop='opaque' placement='left' trigger='click'>
                                                <PopoverTrigger>
                                                    <Button color="danger" size="sm" isIconOnly>
                                                        <Cross size='6' color='white' />
                                                    </Button>
                                                </PopoverTrigger>
                                                <PopoverContent>
                                                    <div className="px-2 py-4">
                                                        <div className="text-small font-bold">Are you sure you want to remove this admin ?</div>
                                                        <div className="flex pt-4 justify-center gap-2">
                                                            <Button color="danger" onPress={() => sumbitRemove(person.user_id)}>Remove</Button>
                                                        </div>
                                                    </div>
                                                </PopoverContent>
                                            </Popover>
                                            ) : (
                                                <span className="text-small font-semibold">You</span>
                                            )}

                                        </div>
                                    ))}
                                </ModalBody>
                                <ModalFooter>
                                    <div className="flex flex-col w-full items-center gap-2">
                                        <Input type="email" label="Email" onChange={(e) => setEmailEntered(e.target.value)} />


                                        <div className="flex flex-row justify-between items-center w-full gap-2">
                                            <Button className="w-1/2" color="primary" onPress={submitAdd}>Add to admins</Button>

                                            <Popover backdrop='opaque'>
                                                <PopoverTrigger>
                                                    <Button color="danger" variant='bordered' className="w-1/2" startContent={<Key size='6' />}>Give Ownership</Button>
                                                </PopoverTrigger>
                                                <PopoverContent>
                                                    <div className="px-2 py-4">
                                                        <div className="text-small font-bold">You are about to transfer the ownership to {emailEntered}.</div>
                                                        <div className="text-small">You will lose all your admin rights on this business</div>

                                                        <div className="flex pt-4 justify-center gap-2">
                                                            <Button color="danger" onPress={sumbitTransfer}>Transfer</Button>
                                                        </div>
                                                    </div>
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                    </div>
                                </ModalFooter>
                            </div>
                        )}
                    </ModalContent>
                </Modal>
            )}
        </>
    );
}


export default ManageBusinessStaff;